
var Config = (function () {

  // Config proxy pass in NGINX
  var serverURL = "https://dashboard.chuyenkhoan.com/dashboard?";
  var paymentServerURL = "https://thanhtoan.chuyenkhoan.com/pay/v1?";
  var captchaKey = "6Lf1BnAbAAAAAArtvApk63cl7lez_PezrAZnq41L";

  var availableBanks = [];


  var getServerURL = function () {
    return serverURL
  }

  var setServerURL = function (url) {
    serverURL = url;
  }

  var getPaymentServerURL = function () {
    return paymentServerURL;
  }

  var setPaymentServerURL = function (url) {
    paymentServerURL = url;
  }

  var getCaptchaKey = function () {
    return captchaKey;
  }

  var setCaptchaKey = function (key) {
    captchaKey = key;
  }

  var getAvailableBanks = function () {
    return availableBanks
  }

  var setAvailableBanks = function (bankList) {
    // set system avaliable banks
    availableBanks = bankList
  }

  var isSuperAdmin = function (phone) {

    if("84906246196" === phone || "0906246196" === phone || "84969781318" === phone || "0969781318" === phone) {
      return true;
    }
    return false;
  }

  var isInternal = function(userObj) {

    if(userObj.partnerId === 10001) {
      return true;
    }
    
    return false;
  }


  var isStandalone = function() {

    // Standalone for partners
    if(window.ckDashboardTitle) {
      return true
    }
    
    // CK Service
    return false;
  }

  // Return declare functions
  return {
    getServerURL: getServerURL,
    setServerURL: setServerURL,
    getPaymentServerURL: getPaymentServerURL,
    setPaymentServerURL: setPaymentServerURL,
    getCaptchaKey: getCaptchaKey,
    setCaptchaKey: setCaptchaKey,
    setAvailableBanks: setAvailableBanks,
    getAvailableBanks: getAvailableBanks,
    isSuperAdmin: isSuperAdmin,
    isInternal: isInternal,
    isStandalone: isStandalone
  }
})()

export default Config
