import React from 'react';
import CKPayment from './components/CKPayment';

// ROOT PAYMENT APP
function App() { 
  return (
    <CKPayment/>
  );
}

export default App;
